let projectsMap = {
    "projects": {
      "leadgen": {
        "meta": {
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        },
        "leadSquared": {
          // "c2l":"C2L",
          "GrandTotalCRMLeads": "Grand Total CRM Leads",
          "CRMqualified":" Qualified CRM Leads-Online",
          "CRMqualifiedInvalid": "Qualified CRM Leads - Invalid",
          "TotalQualifiedLeads": "Total Qualified Leads",
          "PercentageQuality": "% Quality",
          "CRMadmission": " Online Admissions",
          "CRMOrganicAdmission": "Organic Admissions",
          "TotalAdmissions": "Total Admissions ",
          "CRMadmissionInvalid": "CRM Admission Invalid",
          "CRMtotal": "CRM Total",
          "CRMtotalInvalid": "CRM Total Invalid" 
          // "C2L":"C2L", // new additions
          // "GrandTotalCRMLeads":"Grand Total CRM Leads",
          // "CostCRMLeads":"Cost/CRM Leads",
          // "QualifiedCRMLeadsOnline":"Qualified CRM Leads - Online",
          // "QualifiedCRMLeadsInvalid":"Qualified CRM Leads - Invalid",
          // "TotalQualifiedLeads":"Total Qualified Leads",
          // "CostQualityLeads":"Cost/ Quality Leads",
          // "QualityPercent":"% Quality",
          // "OnlineAdmissions":"Online Admissions",
          // "OrganicAdmissions":"Organic Admissions",
          // "TotalAdmissions":"Total Admissions"
        },
        "withLeadSalesforce": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          "sales": "Sales",
          "qualityLeads": "Quality Leads",
          "costPerQualityLeads": "CPQL",
          "siteVisits": "Site Visits",
        },
        "withLeadSquared": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          // "ROAS": "ROAS",
          "cpl": "CPL",
          "c2l":"C2L",
          "GrandTotalCRMLeads": "Grand Total CRM Leads",
          "CRMqualified":" Qualified CRM Leads-Online",
          "CRMqualifiedInvalid": "Qualified CRM Leads - Invalid",
          "TotalQualifiedLeads": "Total Qualified Leads",
          "PercentageQuality": "% Quality",
          "CRMadmission": " Online Admissions",
          "CRMOrganicAdmission": "Organic Admissions",
          "TotalAdmissions": "Total Admissions ",
          "CRMadmissionInvalid": "CRM Admission Invalid",
          "CRMtotal": "CRM Total",
          "CRMtotalInvalid": "CRM Total Invalid" 
          // "CRMadmission": "CRM Admission",
          // "CRMadmissionInvalid": "CRM Admission Invalid",
          // "CRMqualified": "CRM Qualified",
          // "CRMqualifiedInvalid": "CRM  Qualified Invalid",
          // "CRMtotal": "CRM Total",
          // "CRMtotalInvalid": "CRM Total Invalid",
          // // "C2L":"C2L", // new additions
          // "GrandTotalCRMLeads":"Grand Total CRM Leads",
          // // "CostCRMLeads":"Cost/CRM Leads",
          // "QualifiedCRMLeadsOnline":"Qualified CRM Leads - Online",
          // "QualifiedCRMLeadsInvalid":"Qualified CRM Leads - Invalid",
          // "TotalQualifiedLeads":"Total Qualified Leads",
          // // "CostQualityLeads":"Cost/ Quality Leads",
          // "QualityPercent":"% Quality",
          // "OnlineAdmissions":"Online Admissions",
          // "OrganicAdmissions":"Organic Admissions",
          // "TotalAdmissions":"Total Admissions"
        },
        "withoutLeadSquared": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value"
        },
      },
      "default": {
        "meta": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "costPerResult": "CPR",
          "conversions": "Conversions",
          "ctr": "CTR %",
          "conversionValue": "Conversion Value",
          "ROAS": "ROAS",
          "webPurchases": "Web Purchases",
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value"
        },
        "withLeadSquared": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value",
          "c2l":"C2L",
          "GrandTotalCRMLeads": "Grand Total CRM Leads",
          "CRMqualified":" Qualified CRM Leads-Online",
          "CRMqualifiedInvalid": "Qualified CRM Leads - Invalid",
          "TotalQualifiedLeads": "Total Qualified Leads",
          "PercentageQuality": "% Quality",
          "CRMadmission": " Online Admissions",
          "CRMOrganicAdmission": "Organic Admissions",
          "TotalAdmissions": "Total Admissions ",
          "CRMadmissionInvalid": "CRM Admission Invalid",
          "CRMtotal": "CRM Total",
          "CRMtotalInvalid": "CRM Total Invalid" 
          // "CRMadmission": "CRM Admission",
          // "CRMadmissionInvalid": "CRM Admission Invalid",
          // "CRMqualified": "CRM Qualified",
          // "CRMqualifiedInvalid": "CRM  Qualified Invalid",
          // "CRMtotal": "CRM Total",
          // "CRMtotal": "CRM Total",
          // "C2L":"C2L", // new additions
          // "GrandTotalCRMLeads":"Grand Total CRM Leads",
          // "CostCRMLeads":"Cost/CRM Leads",
          // "QualifiedCRMLeadsOnline":"Qualified CRM Leads - Online",
          // "QualifiedCRMLeadsInvalid":"Qualified CRM Leads - Invalid",
          // "TotalQualifiedLeads":"Total Qualified Leads",
          // "CostQualityLeads":"Cost/ Quality Leads",
          // "QualityPercent":"% Quality",
          // "OnlineAdmissions":"Online Admissions",
          // "OrganicAdmissions":"Organic Admissions",
          // "TotalAdmissions":"Total Admissions"
        },
        "withoutLeadSquared": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value"
        },

      }
    },
    "zoneWiseData": {
      "leadgen": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          "actions":"action"
          // "costPerConversion": "CPL"
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
           "actions":"action"
          // "costPerConversion": "CPL"
        }
      },
      "default": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "costPerResult": "CPR",
          "conversions": "Conversions",
          "ctr": "CTR %",
          "conversionValue": "Conversion Value",
          "ROAS": "ROAS",
          //  "actions":"action"
          
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value",
          //  "actions":"action"
        }
      }
    },
    "zoneFilteredWiseData": {
      "leadgen": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          
          // "costPerConversion": "CPL"
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
         
          // "costPerConversion": "CPL"
        },
        "withLeadSalesforce": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          "sales": "Sales",
          "qualityLeads": "Quality Leads",
          "costPerQualityLeads": "CPQL",
          "siteVisits": "Site Visits",
        },

      },
      "default": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "costPerResult": "CPR",
          "conversions": "Conversions",
          "ctr": "CTR %",
          "conversionValue": "Conversion Value",
          "ROAS": "ROAS",
          //  "actions":"action"
          
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value",
          //  "actions":"action"
        }
      }
    },
    "zoneGoogleWiseData": {
      "leadgen": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        }
      },
      "default": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "costPerResult": "CPR",
          "conversions": "Conversions",
          "ctr": "CTR %",
          "conversionValue": "Conversion Value",
          "ROAS": "ROAS",
          //  "actions":"action"
          
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value",
          //  "actions":"action"
        }
      }
    },
    "metaZoneWiseData": {
      "leadgen": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        }
      },
      "default": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "costPerResult": "CPR",
          "conversions": "Conversions",
          "ctr": "CTR %",
          "conversionValue": "Conversion Value",
          "ROAS": "ROAS",
          //  "actions":"action"
          
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value",
          //  "actions":"action"
        }
      }
    },
    "productWiseData": {
      "leadgen": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        },
        "googleAds": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        },
        "withLeadSalesforce": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          "sales": "Sales",
          "qualityLeads": "Quality Leads",
          "costPerQualityLeads": "CPQL",
          "siteVisits": "Site Visits",
        },
      },
      "default": {
        "meta": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "costPerResult": "CPR",
          "conversions": "Conversions",
          "ctr": "CTR %",
          "conversionValue": "Conversion Value",
          "ROAS": "ROAS"
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value"
        }
      }
    }
  }
  export default projectsMap